import React, { Component } from "react";
import ImgLinkedin from "../../assets/img/partners/linkedin.png";
import ImgAngeList from "../../assets/img/partners/angellist.png";
import ImgProductHunt from "../../assets/img/partners/producthunt.png";
import ImgStackOverflow from "../../assets/img/partners/stackoverflow.png";

export default class Partners extends Component {
  render() {
    return (
      <section className="ftco-section ftco-partner">
        <div className="container">
          <div className="row">
            <div className="col-sm ftco-animateX">
              <a href="https://www.linkedin.com/in/platerocm/" rel="noopener noreferrer" target="_blank" className="partner black-filter">
                <img
                  src={ImgLinkedin}
                  className="img-fluid"
                  alt="Linkedin Logo"
                />
              </a>
            </div>
            <div className="col-sm ftco-animateX">
              <a href="https://angel.co/platerocm" rel="noopener noreferrer" target="_blank" className="partner black-filter">
                <img
                  src={ImgAngeList}
                  className="img-fluid"
                  alt="Angel List Logo"
                />
              </a>
            </div>
            <div className="col-sm ftco-animateX">
              <a href="https://www.producthunt.com/@platerocm/" rel="noopener noreferrer" target="_blank" className="partner black-filter">
                <img
                  src={ImgProductHunt}
                  className="img-fluid"
                  alt="Product Hunt Logo"
                />
              </a>
            </div>
            <div className="col-sm ftco-animateX">
              <a href="https://stackoverflow.com/story/platerocm" rel="noopener noreferrer" target="_blank" className="partner black-filter">
                <img
                  src={ImgStackOverflow}
                  className="img-fluid"
                  alt="StackOverflow Logo"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
