import React from "react";
import ImgAbout from "../../assets_template/images/about.jpg";

class Contact extends React.Component {
  render() {
    return (
      <section
        className="ftco-section contact-section ftco-no-pb"
        id="contact-section"
      >
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 heading-section text-center ftco-animateX">
              <h1 className="big big-2">Contact</h1>
              <h2 className="mb-4">Contact Me</h2>
              <p>Feel free to stay in touch in any platform -> @platerocm</p>
            </div>
          </div>

          <div className="row d-flex contact-info mb-5">
            <div className="col-md-6 col-lg-3 d-flex ftco-animateX">
              <div className="align-self-stretch box text-center p-4 shadow">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="icon-twitter"></span>
                </div>
                <div>
                  <h3 className="mb-4">Send DM</h3>
                  <p>
                    <a href="https://twitter.com/platerocm">@platerocm</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex ftco-animateX">
              <div className="align-self-stretch box text-center p-4 shadow">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="icon-angellist"></span>
                </div>
                <div>
                  <h3 className="mb-4">Startups</h3>
                  <p>
                    <a href="https://angel.co/platerocm"> @platerocm </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex ftco-animateX">
              <div className="align-self-stretch box text-center p-4 shadow">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="icon-linkedin"></span>
                </div>
                <div>
                  <h3 className="mb-4">More classic</h3>
                  <p>
                    <a href="https://linkedin.com/in/platerocm">in/platerocm</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex ftco-animateX">
              <div className="align-self-stretch box text-center p-4 shadow">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="icon-globe"></span>
                </div>
                <div>
                  <h3 className="mb-4">Partners</h3>
                  <p>
                    <a href="https://pandagoals.com">pandagoals.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row no-gutters block-9" style={{ display: `none` }}>
            <div className="col-md-6 order-md-last d-flex">
              <form action="#" className="bg-light p-4 p-md-5 contact-form">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Email"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="7"
                    className="form-control"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="form-group">
                  <input
                    type="submit"
                    value="Send Message"
                    className="btn btn-primary py-3 px-5"
                  />
                </div>
              </form>
            </div>

            <div className="col-md-6 d-flex">
              <div
                className="img"
                style={{ backgroundImage: `url(${ImgAbout})` }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
