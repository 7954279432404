import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import HomeMain from '../pages/HomeMain';
import './App.css';

import './AppNow.css';
import '../assets_template/css/bootstrap.min.css'
import '../assets_template/css/open-iconic-bootstrap.min.css'
import '../assets_template/css/animate.css'
import '../assets_template/css/owl.carousel.min.css'
import '../assets_template/css/owl.theme.default.min.css'
import '../assets_template/css/magnific-popup.css'
// import '../assets_template/css/aos.css'
import '../assets_template/css/ionicons.min.css'
import '../assets_template/css/flaticon.css'
import '../assets_template/css/icomoon.css'
import '../assets_template/css/style.css'


import AOS from 'aos';
import 'aos/dist/aos.css';

class App extends React.Component{

  componentDidMount(){
    AOS.init({
      duration: 800,
      easing: 'slide'
    })
  }

  render(){
    return(
      <BrowserRouter>
        <Route path="/" component={HomeMain} />
      </BrowserRouter>
    )
  }
}

export default App;
