import React, { Component } from "react";

class Skill extends Component {
  render() {
    return (
      <div className="col-md-3 animate-box">
        <div className="progress-wrap ftco-animateX">
          <h3>{this.props.skill}</h3>
          <div className="progress" style={{ height: `4px` }}>
              <div
                className="progress-bar color-6"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `100%` }}
              >
              <a href={this.props.website} target='_blank' rel="noopener noreferrer" style={{cursor : `pointer`}}>
                <span>
                +
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Skill;
