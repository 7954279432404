import React, { Component } from "react";
import moment from "moment";

class ExpMiniCat extends Component {
  getTotalTime = (startM, startY, endM, endY) => {
    // Convertir startM y endM a enteros y ajustar el mes de finalización si es necesario
    startM = parseInt(startM, 10) - 1; // Ajuste para moment.js (0-indexado)
    endM = endM ? parseInt(endM, 10) - 1 : null; // Ajustar si endM está definido

    // Asignar valores predeterminados si son fechas actuales
    if (endY === 'Current') {
      endY = new Date().getFullYear();
      endM = endM === null ? new Date().getMonth() : endM; // Usar mes actual si endM no está definido
    }

    // Crear fechas usando moment.js
    const startDate = moment([startY, startM]);
    const endDate = moment([endY, endM]);

    // Ajustar la fecha de finalización para incluir el mes final completo
    const adjustedEndDate = endDate.isBefore(startDate, 'month') ? endDate.add(1, 'month') : endDate;

    // Calcular la diferencia en años y meses
    let years = adjustedEndDate.diff(startDate, 'years');
    let months = adjustedEndDate.diff(startDate, 'months');

    // Ajustar la diferencia en años y meses para incluir el mes inicial
    months = months % 12;
    if (startDate.isBefore(adjustedEndDate, 'month')) {
      months += 1;
    }
    if (months === 12) {
      months = 0;
      years += 1;
    }

    // Construir el resultado
    let result = '';
    if (years > 0) {
      result += `${years} year${years > 1 ? 's' : ''}`;
    }
    if (months > 0 || years === 0) {
      if (result) result += ', ';
      result += `${months} month${months > 1 ? 's' : ''}`;
    }
    
    // Si no hay años ni meses, retornar "0 months"
    if (!result) {
      result = '0 months';
    }

    return result;
  };

  getMonthName = (monthNumber) => {
    // Array de nombres de meses en inglés
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return months[monthNumber];
  };

  render() {
    return (
      <div>
        <h2 className="heading">Experience</h2>
        {this.props.experiences.map(element => (
          <div key={element.key} className="resume-wrap d-flex ftco-animateX">
            <div className="icon d-flex align-items-center justify-content-center">
              <span className={element.iconCss}></span>
            </div>
            <div className="text pl-3">
              <span className="date">
                {this.getMonthName(parseInt(element.fromM, 10) - 1)}/{element.fromY} &nbsp; ⇒ &nbsp;
                {element.toM ? `${this.getMonthName(parseInt(element.toM, 10) - 1)}/${element.toY !== 'Current' ? element.toY : ''}` : ''} &nbsp; &nbsp;
                ({this.getTotalTime(element.fromM, element.fromY, element.toM, element.toY)})
              </span>
              <h2>{element.title}</h2>
              <a href={element.website} rel="noopener noreferrer" target="_blank">
                <span className="icon-link"></span>
              </a>
              <span className="position"> {element.place}</span>
              <p style={{ whiteSpace: 'pre-wrap' }}> {element.description} </p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default ExpMiniCat;
