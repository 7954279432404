import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <div>
        <footer className="ftco-footer ftco-section">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md">
                <div className="ftco-footer-widget mb-4">
                  <h2 className="ftco-heading-2">Connect</h2>
                  <p>
                    Thank you to visit my personal website, connect for more
                    information
                  </p>
                  <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                    <li className="ftco-animateX">
                      <a href="https://twitter.com/platerocm">
                        <span className="icon-twitter"></span>
                      </a>
                    </li>
                    <li className="ftco-animateX">
                      <a href="https://angel.co/platerocm">
                        <span className="icon-angellist"></span>
                      </a>
                    </li>
                    <li className="ftco-animateX">
                      <a href="https://linkedin.com/in/platerocm">
                        <span className="icon-linkedin"></span>
                      </a>
                    </li>
                    <li className="ftco-animateX">
                      <a href="https://github.com/platerocm">
                        <span className="icon-github"></span>
                      </a>
                    </li>
                    {/* <li className="ftco-animateX">
                      <a href="https://stackoverflow.com/users/6669133/chris">
                        <span className="icon-stack-overflow"></span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md">
                <div className="ftco-footer-widget mb-4 ml-md-4">
                  <h2 className="ftco-heading-2">Menu</h2>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#home-section">
                        <span className="icon-long-arrow-right mr-2"></span>Home
                      </a>
                    </li>
                    <li>
                      <a href="#about-section">
                        <span className="icon-long-arrow-right mr-2"></span>
                        About
                      </a>
                    </li>
                    <li>
                      <a href="#resume-section">
                        <span className="icon-long-arrow-right mr-2"></span>
                        Resume
                      </a>
                    </li>
                    <li>
                      <a href="#services-section">
                        <span className="icon-long-arrow-right mr-2"></span>
                        Services
                      </a>
                    </li>
                    <li>
                      <a href="#projects-section">
                        <span className="icon-long-arrow-right mr-2"></span>
                        Projects
                      </a>
                    </li>
                    <li>
                      <a href="#contact-section">
                        <span className="icon-long-arrow-right mr-2"></span>
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md">
                <div className="ftco-footer-widget mb-4">
                  <h2 className="ftco-heading-2">Now in 2024</h2>
                  <ul className="list-unstyled">
                    <li>
                      <span className="icon-long-arrow-right mr-2"></span>
                      SPA & PWA
                    </li>
                    <li>
                      <span className="icon-long-arrow-right mr-2"></span>Web
                      API & Sockets
                    </li>
                    <li>
                      <span className="icon-long-arrow-right mr-2"></span>
                      Webviews (Hybrid Apps)
                    </li>
                    <li>
                      <span className="icon-long-arrow-right mr-2"></span>
                      Frontend
                    </li>
                    <li>
                      <span className="icon-long-arrow-right mr-2"></span>
                      More...
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md">
                <div className="ftco-footer-widget mb-4">
                  <h2 className="ftco-heading-2">Learning now</h2>
                  <ul className="list-unstyled">
                    <li>
                      <span className="icon-long-arrow-right mr-2"></span>
                      Sails.js
                    </li>
                    <li>
                      <span className="icon-long-arrow-right mr-2"></span>
                      Go Lang
                    </li>
                    <li>
                      <span className="icon-long-arrow-right mr-2"></span>
                      Travis
                    </li>
                    <li>
                      <span className="icon-long-arrow-right mr-2"></span>
                      React Native
                    </li>
                    <li>
                      <span className="icon-long-arrow-right mr-2"></span>
                      Passport.js
                    </li>
                    <li>
                      <span className="icon-long-arrow-right mr-2"></span>
                      Hapi.js
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center mt-4">
                <p style={{ fontSize: `12px` }}>
                  Christian Platero, Argentina &nbsp; | &nbsp; This template is
                  made with{" "}
                  <i classNAme="icon-heart color-danger" aria-hidden="true"></i>{" "}
                  by Colorlib
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
