import React, { PureComponent } from 'react';
import ImgReact from '../../../assets/img/skills/react.png';
import ImgLaravel from '../../../assets/img/skills/laravel.png';
import ImgNode from '../../../assets/img/skills/nodejs.svg';
import Skill from './Skill';

class SkillsMiniCat extends PureComponent {

  skillsInfo = {
    'Languages & Enviroments' : [
      {'name' : 'TypeScript', 'web' : 'https://www.typescriptlang.org/'},
      {'name' : 'JavaScript', 'web' : 'https://www.ecma-international.org/'},
      {'name' : 'Node.js', 'web' : 'https://nodejs.org/'},
      {'name' : 'AWS', 'web' : 'https://www.aws.amazon.com/'},
      {'name' : 'Googe Cloud', 'web' : 'https://www.cloud.google.com/'},
      {'name' : 'Apache', 'web' : 'https://www.apache.org/'},
      {'name' : 'PHP', 'web' : 'https://php.net'},
    ],
    'Librarys' : [
      {'name' : 'React', 'web' : 'https://reactjs.org/'},
      {'name' : 'Vite', 'web' : 'https://vitejs.dev/'},
      {'name' : 'Zustand', 'web' : 'https://github.com/pmndrs/zustand'},
      {'name' : 'Redux', 'web' : 'https://redux.js.org'},
      {'name' : 'Storybook', 'web' : 'https://storybook.js.org/'},
      {'name' : 'Next.js', 'web' : 'https://nextjs.org/'},
      {'name' : 'Laravel', 'web' : 'https://laravel.com/'},
      {'name' : 'Silex', 'web' : 'https://silex.symfony.com/'},
      //{'name' : 'Gatzby', 'web' : 'https://www.gatsbyjs.org/'},
      {'name' : 'Express', 'web' : 'https://expressjs.com/'},
      {'name' : 'GraphQL', 'web' : 'https://graphql.org/'},
      {'name' : 'Angular.js V1', 'web' : 'https://angularjs.org/'},
    ],
    'Databases & Storage' : [
      {'name' : 'MySQL', 'web' : 'https://www.mysql.com/'},
      {'name' : 'DynamoDB', 'web' : 'https://aws.amazon.com/dynamodb/'},
      {'name' : 'MongoDB', 'web' : 'https://www.mongodb.com/'},
      {'name' : 'Firestore', 'web' : 'https://firebase.google.com/'},
      {'name' : 'GCP Storage', 'web' : 'https://firebase.google.com/'},
      {'name' : 'S3', 'web' : 'https://aws.amazon.com/s3/'},
    ],
    'Services' : [
      {'name' : 'Route53', 'web' : 'https://aws.amazon.com/route53/'},
      {'name' : 'DigitalOcean', 'web' : 'https://www.digitalocean.com/'},
      {'name' : 'Firebase', 'web' : 'https://firebase.google.com/'},
      {'name' : 'Linode', 'web' : 'https://www.linode.com/'},
      {'name' : 'Vercel', 'web' : 'https://vercel.com/'},
      {'name' : 'Chromatic', 'web' : 'https://www.chromatic.com/'},
      {'name' : 'Cloudfare', 'web' : 'https://www.cloudflare.com'},
      {'name' : 'Pentaho', 'web' : 'https://www.hitachivantara.com/en-us/products/data-management-analytics/pentaho-platform.html'},
    ],
    'Tools' : [
      {'name' : 'Webpack', 'web' : 'https://webpack.js.org/'},
      {'name' : 'NPM', 'web' : 'https://www.npmjs.com/'},
      {'name' : 'Postman', 'web' : 'https://www.postman.com/'},
      {'name' : 'Babel', 'web' : 'https://babeljs.io/'},
      {'name' : 'ESLint', 'web' : 'https://eslint.org/'},
      {'name' : 'EsBuild', 'web' : 'https://esbuild.github.io/'},
    ],
  };

  render() {
    return (
        <div>
          <h2 className="heading">Skills and more</h2>
          <div className="row progress-circle mb-5">
            <div className="col-lg-4 mb-4">
              <div className="bg-white rounded-lg shadow p-4">
                <h2 className="h5 font-weight-bold text-center mb-4">React</h2>
    
                {/* <!-- Progress bar 1 --> */}
                <div className="progress mx-auto" data-value="100">
                  <span className="progress-left">
                    <span className="progress-bar border-primary"></span>
                  </span>
                  <span className="progress-right">
                    <span className="progress-bar border-primary"></span>
                  </span>
                  <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                    <div style={{margin : `40px`}}>
                      <img alt="React JS Logo" src={ImgReact} style={{ filter: `drop-shadow(1px 2px 2px #c5c5c5)` }} className="img-fluid"/>
                    </div>
                  </div>
                </div>
                {/* <!-- END --> */}
              </div>
            </div>
    
            <div className="col-lg-4 mb-4">
              <div className="bg-white rounded-lg shadow p-4">
                <h2 className="h5 font-weight-bold text-center mb-4">Laravel</h2>
    
                {/* <!-- Progress bar 1 --> */}
                <div className="progress mx-auto" data-value="100">
                  <span className="progress-left">
                    <span className="progress-bar border-primary"></span>
                  </span>
                  <span className="progress-right">
                    <span className="progress-bar border-primary"></span>
                  </span>
                  <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                    <div className="" style={{margin : `40px`}}>
                      <img alt="Laravel Logo" src={ImgLaravel} style={{ filter: `drop-shadow(1px 3px 3px #c5c5c5)` }} className="img-fluid"/>
                    </div>
                  </div>
                </div>
                {/* <!-- END --> */}
              </div>
            </div>
    
            <div className="col-lg-4 mb-4">
              <div className="bg-white rounded-lg shadow p-4">
                <h2 className="h5 font-weight-bold text-center mb-4">Node.js</h2>
    
                {/* <!-- Progress bar 1 --> */}
                <div className="progress mx-auto" data-value="100">
                  <span className="progress-left">
                    <span className="progress-bar border-primary"></span>
                  </span>
                  <span className="progress-right">
                    <span className="progress-bar border-primary"></span>
                  </span>
                  <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                    <div className="" style={{margin : `40px`}}>
                      <img alt="Node JS Logo" src={ImgNode} style={{ filter: `drop-shadow(1px 3px 3px #c5c5c5)` }} className="img-fluid"/>
                    </div>
                  </div>
                </div>
                {/* <!-- END --> */}
              </div>
            </div>
          </div>
          { Object.keys(this.skillsInfo).map(category => {
            let count = 1;
            return (
            <div>  
              <span>{category} :</span>
              <hr/>
              <div className="row">
                {this.skillsInfo[category].map(skill => {
                  return(<Skill key={count++} skill={skill.name} website={skill.web}/>)
                })}
              </div>
            </div>);
          })}
          {/* <div className="row">
            <Skill skill='PHP' website='php.net'/>
          </div> */}
        </div>
    );
  }
}

export default SkillsMiniCat;