import React, { Component } from "react";
import CountUp from "react-countup";

export default class Counter extends Component {
  
  render() {
    return (
      <div>
        <section
          className="ftco-section ftco-no-pt ftco-no-pb ftco-counter img"
          id="section-counter"
        >
          <div className="container-fluid px-md-5">
            <div className="row d-md-flex align-items-center">
              <div className="col-md d-flex justify-content-center counter-wrap ftco-animateX">
                <div className="block-18 shadow">
                  <div className="text">
                    <CountUp start={0} prefix="+" end={9} delay={0}>
                      {({ countUpRef }) => (
                        <strong className="number" ref={countUpRef}></strong>
                      )}
                    </CountUp>
                    <span>years of experience</span>
                  </div>
                </div>
              </div>
              <div className="col-md d-flex justify-content-center counter-wrap ftco-animateX">
                <div className="block-18 shadow">
                  <div className="text">
                    <CountUp
                      start={0}
                      prefix="+"
                      end={2138}
                      suffix="/y"
                      delay={0}
                    >
                      {({ countUpRef }) => (
                        <strong className="number" ref={countUpRef}></strong>
                      )}
                    </CountUp>
                    <span>Github Contributions</span>
                  </div>
                </div>
              </div>
              <div className="col-md d-flex justify-content-center counter-wrap ftco-animateX">
                <div className="block-18 shadow">
                  <div className="text">
                    <CountUp
                      start={0}
                      prefix="+"
                      end={24}
                      suffix=" hours"
                      delay={0}
                    >
                      {({ countUpRef }) => (
                        <strong className="number" ref={countUpRef}></strong>
                      )}
                    </CountUp>
                    <span role="img" aria-label="emoji">Fixing Jquery bugs 😂</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
