import React, { Component } from "react";

export default class NowWorking extends Component {
  render() {
    return (
      <section className="ftco-section" id="services-section">
        <div className="container-fluid px-md-5">
          <div className="row justify-content-center py-5 mt-5">
            <div className="col-md-12 heading-section text-center ftco-animateX">
              <h1 className="big big-2">Services</h1>
              <h2 className="mb-4">Now in 2024</h2>
              <p>
                Here is a short list of the types of technologies I am working
                with... (Open for new projects)
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 text-center d-flex ftco-animateX">
              <a href="#services-section" className="services-1 shadow">
                <span className="icon">
                  <i className="flaticon-ux-design"></i>
                </span>
                <div className="desc">
                  <h3 className="mb-5">SPA & PWA</h3>
                  <p>
                    Single-page applications and Progressive Web Apps, working
                    mainly with React and VanillaJS.
                  </p>
                </div>
              </a>
            </div>
            <div className="col-md-4 text-center d-flex ftco-animateX">
              <a href="#services-section" className="services-1 shadow">
                <span className="icon">
                  <i className="flaticon-flasks"></i>
                </span>
                <div className="desc">
                  <h3 className="mb-5">APIs & Sockets</h3>
                  <p>
                    Laravel and Node.JS APIs, Sockets with the magic of
                    socket.io and another tools.
                  </p>
                </div>
              </a>
            </div>
            <div className="col-md-4 text-center d-flex ftco-animateX">
              <a href="#services-section" className="services-1 shadow">
                <span className="icon">
                  <i className="flaticon-ideas"></i>
                </span>
                <div className="desc">
                  <h3 className="mb-5">Webviews (Hybrid Apps)</h3>
                  <p>
                    Update apps without a binary update, using Firebase for
                    simplify and server-side render to make client-side life
                    easy
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
