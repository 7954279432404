import React from 'react';
import Welcome from '../components/welcome/Welcome'
import About from '../components/about/About';
// import BlogLast from '../components/blogpreview/BlogLast';
import LastProjects from '../components/portfolio/LastProjects';
import MiniCategories from '../components/resume/MiniCategories';
import Contact from '../components/uibasic/Contact';
import Counter from '../components/uibasic/Counter';
import Footer from '../components/uibasic/Footer';
import HireMe from '../components/uibasic/HireMe';
import Navigation from '../components/uibasic/Navigation';
import Partners from '../components/uibasic/Partners';
import NowWorking from '../components/uibasic/NowWorking';

class HomeMain extends React.Component{
  
  render(){
    return(
	    <div>
        <Navigation />
        <Welcome />
        <About />
        <Partners />
        <MiniCategories />
        <NowWorking />
        <LastProjects />
        <Counter />
        {/* <BlogLast /> */}
        <HireMe />
        <Contact />
        <Footer />
      </div>
    )
  }
}

export default HomeMain;