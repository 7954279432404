import React, { Component } from "react";
import ImgPanda from "../../assets/img/side_pandagoals.jpg";
import ImgShopInstant from "../../assets/img/side_shopinsta.png";

export default class LastProjects extends Component {
  render() {
    return (
      <section className="ftco-section ftco-project" id="projects-section">
        <div className="container-fluid px-md-0">
          <div className="row no-gutters justify-content-center pb-5">
            <div className="col-md-12 heading-section text-center ftco-animateX">
              <h1 className="big big-2">Projects</h1>
              <h2 className="mb-4">Check out my side projects</h2>
              <p>Hobby, Dream, Random</p>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-md-6">
              <div
                className="project img ftco-animateX d-flex justify-content-center align-items-center"
                style={{ backgroundImage: `url('${ImgPanda}')` }}
              >
                <div className="overlay"></div>
                <div className="text text-center p-4">
                  <h3>
                    <a href="https://pandagoals.com">
                      Panda Goals <span className="icon-link" />
                    </a>
                  </h3>
                  <span>
                    Tools for Remote Teams <br /> (WIP Since 2017)
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="project img ftco-animateX d-flex justify-content-center align-items-center"
                style={{ backgroundImage: `url(${ImgShopInstant})` }}
              >
                <div className="overlay"></div>
                <div className="text text-center p-4">
                  <h3>
                    <a href="goal.ar">
                      Shop Instant <span className="icon-link" />
                    </a>
                  </h3>
                  <span>
                    Sell on Instagram <br /> (Only available in Argentina)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
