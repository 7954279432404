import React, { Component } from 'react'
import AboutImg from '../../assets/img/hero_website.jpg'
import moment from 'moment-timezone';
import { Link } from "react-scroll";


export default class About extends Component {


  localTimeCalc = () => {
    let time = moment(new Date());
    return time.tz('America/Argentina/Buenos_Aires').format('HH:mm');
  }

  render() {
    return (


      <section className="ftco-about img ftco-section ftco-no-pt ftco-no-pb" id="about-section">
        <div className="container">
          <div className="row d-flex no-gutters">
            <div className="col-md-6 col-lg-6 d-flex">
              <div className="img-about img d-flex align-items-stretch">
                <div className="overlay"></div>
                <div
                  className="img d-flex align-self-stretch align-items-center"
                  style={{
                    backgroundImage: `linear-gradient(rgba(62, 100, 255, 0.5), rgba(62, 100, 255, 0.5)), url(${AboutImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                ></div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 pl-md-5 py-5">
              <div className="row justify-content-start pb-3">
                <div className="col-md-12 heading-section ftco-animateX">
                  <h1 className="big">About</h1>
                  <h2 className="mb-4">About Me</h2>
                  <p>Full-Stack Web Engineer with a decade of experience in developing SPAs, PWAs, WebSockets, APIs, Hybrid Apps, and Web Apps. I have consistently worked remotely for global companies, collaborating with a diverse range of professionals, including engineers, designers, UX specialists, founders, product managers, and project leads.</p>
                  <p>My background spans startups, government agencies, and enterprises across industries such as travel, automation, real estate, e-commerce, branding, marketing, and legal. As an innovative developer, I have a proven track record of resolving issues and delivering web solutions that enhance productivity within tech startups.</p>
                  <p>Driven by curiosity about different cultures, I have worked with clients and companies from various locations, including New York, California, Sydney, Amsterdam, Vancouver, Buenos Aires, and Chile.</p>
                  <ul className="about-info mt-4 px-md-0 px-2">
                    <li className="d-flex"><span>Name:</span> <span>Christian Platero</span></li>
                    <li className="d-flex"><span>Country:</span> <span> Argentina </span></li>
                    <li className="d-flex"><span>Current Time:</span> <span> {this.localTimeCalc()} (UTC - 3) </span></li>
                    {/* <li className="d-flex"><span>Date of birth:</span> <span> -- </span></li> */}
                    {/* <li className="d-flex"><span>Address:</span> <span> -- </span></li> */}
                    {/* <li className="d-flex"><span>Zip code:</span> <span> -- </span></li> */}
                  </ul>
                </div>
              </div>
              <div className="counter-wrap ftco-animateX d-flex mt-md-3">
                <div className="text">
                  <p className="mb-4">
                    {/* <span>Look my recomendations here:</span> */}
                  </p>
                  <p><Link className="btn btn-primary py-3 px-3" offset={-120} spy={true} smooth={true} to="page-3">
                    More about Tech & Skills  ⮕
                  </Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }
}
