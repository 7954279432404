import React from 'react';
import ImgBackground1 from '../../assets_template/images/bg_1.jpg'

class HireMe extends React.Component{
  render(){
    return(

  <section className="ftco-section ftco-hireme img" style={{ backgroundImage : `url(${ImgBackground1})` }}>
  <div className="overlay"></div>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-7 ftco-animateX text-center">
        <h2>I'm <span>available</span> for new projects</h2>
        <p>HR, Startup, Founder, Companys ?</p>
        <p className="mb-0"><a href="https://linkedin.com/in/platerocm" className="btn btn-primary py-3 px-5">Connect in Linkedin</a></p>
      </div>
    </div>
  </div>
</section>

    )
  }
}

export default HireMe;